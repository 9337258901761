import NavLink from './NavLink';

const NavLinks = ( {onClick} ) => {
    return (
        <ul className="nav-list">
           <NavLink onClick={onClick} to="/#">Связаться</NavLink>
           <NavLink onClick={onClick} to="/#">Услуги</NavLink>
           <NavLink onClick={onClick} to="/#">Наши работы</NavLink>
           <NavLink onClick={onClick} to="/#">Контакты</NavLink>
        </ul>
    )
}

export default NavLinks