import FooterLinks from "./FooterLinks"
import ContactButton from "../ContactButton"

//Page Transition
import { motion } from "framer-motion";
import PageTransitions from "../PageTransition/PageTransitions"
import PageVariants from "../PageTransition/PageVariations"

import Aidalogo from "../../images/aidalogo.svg"
import WhatsApp from "../../images/whatsapp-icon.svg"
import Facebook from "../../images/facebook-icon.svg"
import Insta from "../../images/insta-icon.svg"

const Footer = () => {
  return (
    <motion.footer exit="out" animate="in" initial="initial" variants={PageVariants} transition={PageTransitions} >
        <div className="footer-main">
            <div className="footer-info">
                <h1 className="footer-number">
                    8 702 858 5448
                </h1>
                <ContactButton color="btn-white"/>
            </div>

            <div className="footer-links">
                <div className="footer-nav">
                    <FooterLinks/>
                </div>
            </div>
        </div>

        <div className="footer-supplement">
            <div className="footer-reference">
                <img className="aida-img" src={Aidalogo} alt="aidadesign"/>
                <p className="aida-desc">Задизайнено в <a href="https://aidadesign.kz/">Студии AIDA.design</a><br/><a href="https://aidadesign.kz/">Информация о сайте</a></p>
            </div>
            <div className="footer-social">
                <a href="https://www.whatsapp.com/"> <img width="42px" src={WhatsApp} alt="whatsapp-logo"/></a>
                <a href="https://www.facebook.com/"> <img width="42px" src={Facebook} alt="facebook-logo"/></a>
                <a href="https://www.instagram.com/"> <img width="42px" src={Insta} alt="instagram-logo"/></a>
            </div>
        </div>
    </motion.footer>
  );
};

export default Footer;
